#i7-results-report {
    .message-body {
        p {
            margin-bottom: 0;
        }
    }
    h3.tighter {
        margin-top: 0px;
    }
    p.hang {
        padding-left: 25px;
        text-indent: -25px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    p.in1 {
        padding-left: 0px;
    }
    p.tightin1 {
        padding-left: 0px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin1 {
        padding-left: 0px;
        margin-bottom: 2px;
    }
    p.hangingin1 {
        padding-left: 50px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in2 {
        padding-left: 25px;
    }
    p.tightin2 {
        padding-left: 25px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin2 {
        padding-left: 25px;
        margin-bottom: 2px;
    }
    p.hangingin2 {
        padding-left: 75px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in3 {
        padding-left: 50px;
    }
    p.tightin3 {
        padding-left: 50px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin3 {
        padding-left: 50px;
        margin-bottom: 2px;
    }
    p.hangingin3 {
        padding-left: 100px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in4 {
        padding-left: 75px;
    }
    p.tightin4 {
        padding-left: 75px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin4 {
        padding-left: 75px;
        margin-bottom: 2px;
    }
    p.hangingin4 {
        padding-left: 125px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in5 {
        padding-left: 100px;
    }
    p.tightin5 {
        padding-left: 100px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin5 {
        padding-left: 100px;
        margin-bottom: 2px;
    }
    p.hangingin5 {
        padding-left: 150px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in6 {
        padding-left: 125px;
    }
    p.tightin6 {
        padding-left: 125px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin6 {
        padding-left: 125px;
        margin-bottom: 2px;
    }
    p.hangingin6 {
        padding-left: 175px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in7 {
        padding-left: 150px;
    }
    p.tightin7 {
        padding-left: 150px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin7 {
        padding-left: 150px;
        margin-bottom: 2px;
    }
    p.hangingin7 {
        padding-left: 200px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in8 {
        padding-left: 175px;
    }
    p.tightin8 {
        padding-left: 175px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin8 {
        padding-left: 175px;
        margin-bottom: 2px;
    }
    p.hangingin8 {
        padding-left: 225px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    p.in9 {
        padding-left: 200px;
    }
    p.tightin9 {
        padding-left: 200px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    p.halftightin9 {
        padding-left: 200px;
        margin-bottom: 2px;
    }
    p.hangingin9 {
        padding-left: 250px;
        text-indent: -50px;
        margin-top: 0px;
        margin-bottom: 1px;
    }
}

#compiler-status-container {
    .message-body {
        white-space: pre-wrap;
    }
}