$manager-width: 250px;

#filemanager {
    border-right: 1px solid #ecf0f1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    width: $manager-width;

    .subtitle {
        margin-bottom: 1em;
        margin-top: 0.5em;
        text-align: center;
    }

    .filelisting {
        flex-grow: 1;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

#dropzone-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    + #split {
        max-width: calc(100% - #{$manager-width});
    }

    &:focus {
        outline: none;
    }
}

#dropzone-drag-active-overlay {
    align-items: center;
    background-color: #ecf0f1;
    border: 3px dashed #333;
    color: #555;
    display: flex;
    font-size: 24px;
    opacity: 0.9;
    text-align: center;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}