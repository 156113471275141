// make the cards have same height
#release-options-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .column {
        display: flex;

        .card {
            display: flex;
            flex-direction: column;
            
            .card-content {
                flex-grow: 1;
            }
        }
    }
}