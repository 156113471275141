
.modal-card.is-wide {
    margin: 1rem;
    max-width: 1024px;
    width: auto;
}

footer.modal-card-foot {
    display: flex;
    justify-content: space-between;
}